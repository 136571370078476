<template>
  <div class="user-detail-container">
    <el-row :gutter="20" class="main-content" style="margin:0 auto">
      <el-col :span="17">
        <div class="content">
          <!-- 用户信息卡片 -->
          <el-card class="user-info-card" shadow="hover">
            <div class="user-header">
              <el-avatar :size="100" :src="form.avatar || 'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png'"></el-avatar>
              <div class="user-title">
                <h2>{{ form.nickname || form.username }}</h2>
                <p class="user-role">{{ form.role }}</p>
              </div>
            </div>
            
            <el-descriptions class="margin-top" :column="3" border>
              <el-descriptions-item>
                <template slot="label">
                  <i class="el-icon-user"></i>
                  用户ID
                </template>
                {{ form.id }}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  <i class="el-icon-user"></i>
                  账号
                </template>
                {{ form.username }}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  <i class="el-icon-message"></i>
                  邮箱
                </template>
                {{ form.email }}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  <i class="el-icon-phone"></i>
                  手机号
                </template>
                {{ form.phone }}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  <i class="el-icon-location"></i>
                  地址
                </template>
                {{ form.address }}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  <i class="el-icon-wallet"></i>
                  账户余额
                </template>
                <span class="balance">¥{{ form.balance }}</span>
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  <i class="el-icon-time"></i>
                  注册时间
                </template>
                {{ form.create_time }}
              </el-descriptions-item>
            </el-descriptions>
          </el-card>

          <!-- 游戏列表 -->
          <el-card class="game-list-card" shadow="hover">
            <div slot="header" class="card-header">
              <span><i class="el-icon-game"></i> 我拥有的游戏</span>
            </div>
            <div class="game-list" v-loading="loading" element-loading-text="正在获取您的游戏">
              <el-row :gutter="20">
                <el-col :span="8" v-for="i in gameList" :key="i.id">
                  <el-card class="game-card" shadow="hover" @click.native="changeGogame(i)">
                    <img :src="i.imgsrc" class="game-image">
                    <div class="game-info">
                      <h3 class="game-name">{{ i.name }}</h3>
                      <div class="game-tags">
                        <el-tag size="small" type="success">好评率: {{ i.goodrating }}%</el-tag>
                        <el-tag size="small" type="warning">价格: {{ i.price }}</el-tag>
                      </div>
                      <div class="game-meta">
                        <span>发行日期: {{ i.releasedate }}</span>
                        <span>开发商: {{ i.developers }}</span>
                      </div>
                    </div>
                  </el-card>
                </el-col>
              </el-row>
            </div>
          </el-card>
        </div>
      </el-col>

      <!-- 右侧充值记录 -->
      <el-col :span="7">
        <el-card class="recharge-card" shadow="hover">
          <div slot="header" class="card-header">
            <span><i class="el-icon-money"></i> 充值记录</span>
          </div>
          <div class="recharge-list" style="height:700px;overflow-y:auto">
            <el-card v-for="i in userfee" 
                     :key="i.rechargerecord_id" 
                     class="recharge-item"
                     shadow="hover">
              <el-descriptions :column="1">
                <el-descriptions-item label="套餐">
                  <span class="recharge-package">{{ i.recharge_id }}</span>
                </el-descriptions-item>
                <el-descriptions-item label="订单号">
                  <span class="order-number">{{ i.order_number }}</span>
                </el-descriptions-item>
                <el-descriptions-item label="购买时间">
                  <span class="purchase-time">{{ i.entry_time }}</span>
                </el-descriptions-item>
              </el-descriptions>
            </el-card>
          </div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>

// import Swiperlist from "@/components/Swiperlist";
import axios from "axios";

export default {
  components: {
    // Swiperlist,
  },
  name: "userdetail",
  data() {
    return {
      gameList: {},
      form: {},
      user: {},
      userfee: {},
      loading:true
    }
  },
  created() {
    let user = localStorage.getItem("user") || "{}"
    this.form = JSON.parse(user)
    this.gameget()
    this.getuserfee()
    this.update()
  },
  methods: {
    changeGogame(res) {
      this.$router.push({
        name: "gameDetail",
        query: {
          id: res.id,
        },
      });
    },

    //分页方法
    gameget() {
      this.loading=true
      axios.get(this.$globle.allurl + "getusergame", {
        params: {
          userid: this.form.id,
        }
      }).then(res => {
        this.loading=false
        this.gameList = res.data
        this.total = res.data.total
        for (let i = 0; i < this.gameList.length; i++) {
          this.gameList[i].imgsrc = "https://media.st.dl.eccdnx.com/steam/apps/" + this.gameList[i].gameid + "/header.jpg"
          if (this.gameList[i].price === 0) {
            this.gameList[i].price = "免费开玩"
          }
        }
      })
    },
    getuserfee() {
      axios.get(this.$globle.allurl + "fee/userfeerecord", {
        params: {
          userid: this.form.id,
        }
      }).then(res => {
        this.userfee = res.data
      })
    },
    update() {
      axios.get(this.$globle.allurl + "user/getoneuser", {
        params: {
          userid: this.form.id
        }
      }).then(res => {
        this.form = res.data
      })
    }
  }
}
</script>

<style scoped>
.user-detail-container {
  padding: 20px;
  background-color: #f5f7fa;
  min-height: 100vh;
}

.main-content {
  max-width: 1400px;
  margin: 0 auto;
}

.user-info-card {
  margin-bottom: 20px;
}

.user-header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  padding: 20px;
  background: linear-gradient(135deg, #1890ff 0%, #36cfc9 100%);
  border-radius: 8px;
  color: white;
}

.user-title {
  margin-left: 20px;
}

.user-title h2 {
  margin: 0;
  font-size: 24px;
}

.user-role {
  margin: 5px 0 0;
  font-size: 14px;
  opacity: 0.8;
}

.balance {
  color: #f56c6c;
  font-weight: bold;
  font-size: 18px;
}

.card-header {
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
}

.card-header i {
  margin-right: 8px;
  color: #1890ff;
}

.game-list {
  margin-top: 20px;
}

.game-card {
  margin-bottom: 20px;
  cursor: pointer;
  transition: all 0.3s;
}

.game-card:hover {
  transform: translateY(-5px);
}

.game-image {
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-radius: 4px;
}

.game-info {
  padding: 10px 0;
}

.game-name {
  margin: 0;
  font-size: 16px;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.game-tags {
  margin: 10px 0;
}

.game-meta {
  font-size: 12px;
  color: #909399;
}

.game-meta span {
  margin-right: 10px;
}

.recharge-card {
  height: 100%;
}

.recharge-item {
  margin-bottom: 15px;
  transition: all 0.3s;
}

.recharge-item:hover {
  transform: translateX(5px);
}

.recharge-package {
  color: #1890ff;
  font-weight: bold;
}

.order-number {
  color: #909399;
  font-size: 14px;
}

.purchase-time {
  color: #67c23a;
}

.margin-top {
  margin-top: 20px;
}

/* 自定义滚动条样式 */
.recharge-list::-webkit-scrollbar {
  width: 6px;
}

.recharge-list::-webkit-scrollbar-thumb {
  background-color: #909399;
  border-radius: 3px;
}

.recharge-list::-webkit-scrollbar-track {
  background-color: #f5f7fa;
  border-radius: 3px;
}
</style>
